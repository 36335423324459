<template>
    <v-container>
        <!-- Content for mat or tab format -->
                <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
                <!-- Login form -->
                <v-flex lg12 sm12 xs12 xl12>
                    <v-text-field v-model="userDetails.EmailId" outlined dense label="Email ID" required
                        :rules="emailRules"></v-text-field>
                </v-flex>
                <v-flex lg12 sm12 xs12 xl12>
                    <v-text-field v-model="userDetails.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'" outlined dense label="Password" :rules="passwordRules"
                        required @click:append="showPassword = !showPassword"></v-text-field>
                </v-flex>
                <v-flex lg12 sm12 xs12 xl12>
                    <v-btn @click="$emit('login')" :disabled="ActivateValidation" dense color="primary">
                        Login
                    </v-btn>
                </v-flex>
                <v-flex class="mt-5">
                    <p @click="$emit('forget')" style="cursor: pointer;" class="primary--text">Forgot Password?</p>
                    <p @click="$emit('signup')" style="cursor: pointer;" class="primary--text">sign up</p>
                    <p  class="primary--text"><a :href="emailLink" style=" text-decoration: none;">Queries & Support</a></p>
                    <p class="primary--text">( demo version free to use)</p>

                </v-flex>
        </v-form>

    </v-container>

</template>
<script>
export default {
    data() {
        return {
            email: 'profileMatcher2024@gmail.com',
      subject: 'Queries regarding Profile Matching',
      body: 'Dear Team,\n\nI have the following queries:\n\n',
            valid: false,
            emailRules: [
                value => !!value || 'Email is required',
                value => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) || 'Email must be valid'
            ],
            passwordRules: [
        value => !!value || 'Password is required',
        value => value.length >= 6 || 'Password must be at least 6 characters'
      ],
      showPassword:false
        }
    },
    props: {
        userDetails: {}, ActivateValidation: Boolean
    },
    computed: {
    emailLink() {
      const queryParams = new URLSearchParams({
        subject: this.subject,
        body: this.body
      });
      return `mailto:${this.email}?${queryParams.toString()}`;
    }
  }
}
</script>