<template>
  <div :class="$vuetify.breakpoint.width > 900 ? 'background' : 'mobileBackground'">

    <v-card class="text-center elevation-3  rounded-xxl " width="300">
      <v-card-title class="d-flex justify-center align-center">
        <v-icon class="red--text ma-1">mdi-heart</v-icon>
        <!-- <span :style="{ fontFamily: 'cursive', color: 'blue' }">Profile Matcher</span> -->
        <h2 :style="{ fontFamily: 'cursive', color: '#D30499' }">Profile Matcher</h2>
      </v-card-title>
      <v-card-text>
        <v-window v-model="activetab" height="auto" :touchless="true">
          <v-window-item v-for="(item, index) in carouselItems" :key="index">
  
            <login :userDetails="userDetails" :ActivateValidation="ActivateValidation" @login="loginValidation()"
              @forget="Forgopassword()" @signup="signup()" v-if="item.type === 'loginAuth'"></login>
           
            <signup :userDetails="userDetails" :profileCreatedList="profileCreatedList" :currentDate="currentDate"
              @Back=" activetab = 0, userDetails = Object.assign({}, DefaultDetails)"
              :ActivateValidation="ActivateValidation" @signup="SignupValidation()"
              v-else-if="item.type === 'signupAuth'" :maxDate="maxDate"></signup>
            <forgetPassword :userDetails="userDetails" @forget="Checkuser()"
              @Back=" activetab = 0, userDetails = Object.assign({}, DefaultDetails)" :currentDate="currentDate"
              :maxDate="maxDate" :ActivateValidation="ActivateValidation" v-else-if="item.type === 'Forgotpassword'">
            </forgetPassword>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import login from './login.vue'
import signup from './signup.vue';
import forgetPassword from './forgetPassword.vue';
import EventServices from '@/services/EventServices';
export default {
  components: {
    signup, forgetPassword, login
  },
  data() {
    return {
      currentDate: "",
      valid: true,
      activetab: 0,
      currentDate: "",
      profileCreatedList: ["Self", "Sibling"],
      carouselItems: [
        { type: 'loginAuth' },
        { type: 'signupAuth' },
        { type: 'Forgotpassword' }
      ],
      signupAuth: false,
      loginAuth: true,
      valid: true,
      DefaultDetails: {
        clientId: "",
        password: "",
        MobileNo: "",
        DOB: "",
        EmailId: "",
        userName: "",
        gender: "",
        profileCreated: "",
      },
      userDetails: {
        clientId: "",
        password: "",
        MobileNo: "",
        DOB: "",
        EmailId: "",
        userName: "",
        gender: "",
        profileCreated: "",
      },



    }
  },
  computed: {
    ActivateValidation() {
      console.log("default", this.activetab)
      switch (this.activetab) {
        case 0:
          return this.userDetails.EmailId == "" || this.userDetails.password == "" ||
            !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.userDetails.EmailId) || this.userDetails.password.length < 6
        case 1:
          return this.userDetails.EmailId == "" || !/.+@.+\..+/.test(this.userDetails.EmailId) || this.userDetails.password == "" || this.userDetails.password.length < 6 || this.userDetails.MobileNo == "" || !/^\d{10}$/.test(this.userDetails.MobileNo)
            || this.userDetails.gender == "" || this.userDetails.gender == null || this.userDetails.profileCreated == null || this.userDetails.profileCreated == ""
        case 2:
          return this.userDetails.EmailId == "" || !/.+@.+\..+/.test(this.userDetails.EmailId) ||
            this.userDetails.MobileNo == "" || !/^\d{10}$/.test(this.userDetails.MobileNo)
        default:

          return true; // or return false if there's no default behavior
      }
    },
    maxDate() {
      const currentDate = new Date();

      // Calculate the minimum date 18 years ago
      const minDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
      this.currentDate = minDate.toISOString().substr(0, 10);
      // Format the minimum date as "YYYY-MM-DD"
      return minDate.toISOString().substr(0, 10);

    },
  },
  methods: {

    loginValidation() {
      // this.userDetails.clientId =  this.userDetails.clientId.toUpperCase()
      // this.userDetails.password
      this.$globalData.overlay = true
      EventServices.GetAuthentication(this.userDetails)
        .then((response) => {
          if (response.data.status == "S") {
            this.$globalData.overlay = false
            this.$store.commit('Setclient', response.data.clientId)
            this.$store.commit('setProfilePic', response.data.profilepic)
            // console.log(" response.data.clientId", response.data.clientId)
            localStorage.setItem("clientId", response.data.clientId)
            localStorage.setItem("ProfilePic", response.data.profilepic)
            localStorage.setItem("username", response.data.clientName)


            this.MessageBar("S", response.data.errMsg)
            // this.$refs.form.resetValidation()
            this.$router.push('/profileMatcher')

          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });
    },

    SignupValidation() {
      this.$globalData.overlay = true
      EventServices.SignupValidation(this.userDetails)
        .then((response) => {
          if (response.data.status == "S") {
            this.$globalData.overlay = false
            this.MessageBar("S", response.data.errMsg)
            this.userDetails = Object.assign(this.DefaultDetails)
            // this.userDetails.clientId = response.data.clientId
            // this.activetab = 0
            localStorage.setItem("username", response.data.clientName)
            localStorage.setItem("clientId", response.data.clientId)
            this.$store.commit('Setclient', response.data.clientId)
            this.$router.push("/userProfile")
            // this.$refs.form.resetValidation()
          } else {
            this.loading = false
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });

    },
    resetFormValidation() {
      // console.log(this.$refs.form,"this.$refs.form");
      if (this.$refs.form) {
        this.$refs.form.resetValidation
      }
    },
    Checkuser() {
      this.$globalData.overlay = true
      EventServices.Checkuser(this.userDetails)
        .then((response) => {
          if (response.data.status == "S") {
            this.$globalData.overlay = false
            // this.$store.commit('Setclient', response.data.clientId)
            // this.$store.commit('setProfilePic', response.data.profilepic)
            localStorage.setItem("clientId", response.data.clientId)
            localStorage.setItem("ProfilePic", response.data.profilepic)
            localStorage.setItem("username", response.data.clientName)
            this.MessageBar("S", response.data.errMsg)
            this.$router.push('/profileMatcher')
          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
    },
    Forgopassword() {
      this.userDetails = Object.assign({}, this.DefaultDetails)
      this.resetFormValidation()
      this.activetab = 2
    },
    signup() {
      this.userDetails = Object.assign({}, this.DefaultDetails)
      this.activetab = 1
      this.loginAuth = false
      this.signupAuth = true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resetFormValidation();
    });
  }
}
</script>
<style scoped>
/* .transparent-card {
  background-color: transparent;
} */
.background {
  /* background-image: radial-gradient(white 20%, transparent 0),
                        radial-gradient(white 20%, transparent 0); */
  /* background-size: 30px 30px; */
  /* background-position: 0 0, 15px 15px; */
  /* background-color:transparent; */
  /* background-color: palevioletred; */
  height: 120vh;
  margin-right: 15%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.mobileBackground {
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
