<template>
    <div>
<userMain @getUserProfile="userProfile" :userDetails="userDetails" :dropDownList="dropDownList" ></userMain>
    </div>
</template>
<script>
import userMain from '@/components/userprofile/userMain.vue';
import EventServices from '@/services/EventServices';
export default{
    components:{
        userMain
},
data(){
    return{
        userDetails:{},
        dropDownList:{},
        clientId: this.$store.state.ClientId
    }
},
methods:{
    userProfile() {
            this.$globalData.overlay = true
            EventServices.userProfile(this.clientId)
                .then((response) => {
                    if (response.data.status == "S") {
                        // console.log(response.data);
                        this.userDetails = response.data.clientProfile
                        this.dropDownList = response.data.dropDownList
                        // this.clientPhotos =response.data.clientPosts
                        this.$globalData.overlay = false
                        // this.MessageBar("S", response.data.errMsg)

                    } else {
            this.$globalData.overlay = false

                        this.loading = false
                        this.MessageBar("E", response.data.errMsg);
                    }
                })
                .catch((error) => {
                    this.$globalData.overlay = false
                    this.MessageBar("E", error);
                });
        }
},
mounted(){
    this.userProfile()
}
}
</script>