<template>
  <div>
    <v-card>
      <v-card-title><span class="pink--text">Location Details</span>
        <v-spacer></v-spacer> <v-icon class="red--text" @click="$emit('closeInfo')">mdi-close</v-icon></v-card-title>
      <v-form @submit.prevent="submitForm" class="pa-2">
        <!-- <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="currentResidence" :items="LookupArr(dropDownList.countryList)"
              item-text="text" item-value="value" label="Current Residence"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="stateOfResidence" :items="LookupArr(dropDownList.stateList)"
              item-text="text" item-value="value" label="State of Residence"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="residencyStatus" :items="LookupArr(dropDownList.countryList)"
              item-text="text" item-value="value" label="Residency Status"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="cityOfResidence" :items="LookupArr(dropDownList.districtList)"
              item-text="text" item-value="value" label="City of Residence"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="locations.pincode" :items="pincodeList" label="Pincode">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn type="submit" class="primary elevation-0"> Submit</v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="locations.pincode" @change="getpincodeDetails" :items="dropDownList.pincodeList"
              label="Pincode">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="stateOfResidence" :disabled="true"
              label="State of Residence"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="residencyStatus" :disabled="true"
              label="Residency Status"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cityOfResidence" :disabled="true"
              label="City of Residence"></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn type="submit" class="primary elevation-0"> Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import EventServices from '@/services/EventServices';

export default {
  data() {
    return {
      PincodeDetails:{},
      currentResidence: "",
      stateOfResidence: "",
      residencyStatus: "",
      cityOfResidence: "",
      Indicator: "location",
    };
  },
  props: {
    locations: {}, dropDownList: {}
  },
  methods: {
    assignValue() {
      // this.currentResidence = this.getKeyByValue(this.dropDownList.countryList, this.locations.currentResidence)
      // this.stateOfResidence = this.getKeyByValue(this.dropDownList.stateList, this.locations.stateOfResidence)
      // this.residencyStatus = this.getKeyByValue(this.dropDownList.countryList, this.locations.residencyStatus)
      // this.cityOfResidence = this.getKeyByValue(this.dropDownList.districtList, this.locations.cityOfResidence)



    },
    getKeyByValue(object, value) {
      if (object != undefined) {
        return Object.keys(object).find(key => object[key] === value);
      } else {
        return ""
      }
    },
    submitForm() {
      // this.locations.currentResidence = this.currentResidence
      // this.locations.stateOfResidence = this.stateOfResidence
      // this.locations.residencyStatus = this.residencyStatus
      // this.locations.cityOfResidence = this.cityOfResidence


      this.$emit("location", this.Indicator)
      // Handle form submission logic here, e.g., send data to an API or update the state
    },
    LookupArr(Data) {
      if (Data != null && Data != undefined) {

        return Object.keys(Data).map(key => ({
          value: key,
          text: Data[key]
        }));
      } else {
        return []
      }

    },
    getpincodeDetails() {
      console.log(this.locations.pincode,"this.locations.pincode");
      
      EventServices.GetpincodeDetails(this.locations.pincode)
        .then((response) => {

          this.stateOfResidence = response.data.state
          this.cityOfResidence = response.data.district
          this.residencyStatus = "India"
          
            // this.PincodeDetails = response.data.userProfileList
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });
    }
  },
  mounted() {
    this.assignValue()
  }
};
</script>
