<template>
    <div>
        <ClientRecords :userList="userList" @SearchName="search" @RESET="ClientMaster" :ClientList=ClientList>
        </ClientRecords>
    </div>
</template>
<script>
import EventServices from '@/services/EventServices';
import ClientRecords from '@/components/ClientRecords/ClientRecords.vue';
export default {
    components: {
        ClientRecords
    },
    data() {
        return {
            userList: [],
            clientId: this.$store.state.ClientId,
            ClientList: [],
            FetchChats: false
        }
    },
    methods: {
        GetAllChats() {
            console.log("GetAllChats +");
            if (localStorage.getItem("offlineMsg") != "Y") {

                EventServices.GetUserChats(this.$store.state.ClientId)
                    .then((response) => {
                        if (response.data.status == "S") {
                            console.log(response.data.chatItems, "chatItem");
                            if (response.data.chatItems.length > 0) {
                                console.log("if GetUserChats")

                                this.$store.state.MessageList.push(...response.data.chatItems);
                                localStorage.setItem("MesssageList", JSON.stringify(this.$store.state.MessageList))
                                this.MessageBar("S", "New Message Recived")
                                
                            } else {
                                this.$store.commit('SetMessages', response.data.chatItems)
                                localStorage.setItem("MesssageList", response.data.chatItems)
                            }
                            localStorage.setItem("offlineMsg", "Y")
                        } else {
                            this.MessageBar("E", response.data.errMsg);
                        }
                    })
                    .catch((error) => {
                        this.$globalData.overlay = false
                        this.MessageBar("E", error);
                    });
            }

        },
        search(value) {
            // console.log("search", value)
            EventServices.SearchUser(value)
                .then((response) => {
                    if (response.data.status == "S") {
                        // console.log(response.data);
                        this.userList = response.data.userProfileList
                        this.MessageBar("S", response.data.errMsg)
                        this.$globalData.overlay = false

                    } else {
                        this.loading = false
                        this.$globalData.overlay = false

                        this.MessageBar("E", response.data.errMsg);
                    }
                })
                .catch((error) => {
                    this.$globalData.overlay = false
                    this.MessageBar("E", error);
                });
        },
        ClientMaster() {
            this.$globalData.overlay = true
            // console.log(this.clientId, "clientId");

            EventServices.ProfileMatcher(this.clientId)
                .then((response) => {
                    if (response.data.status == "S") {
                        // console.log(response.data);
                        this.userList = response.data.userProfileList
                        this.ClientList = response.data.clientNameList
                        // this.MessageBar("S", response.data.errMsg)
                        this.$globalData.overlay = false

                    } else {
                        this.loading = false
                        this.$globalData.overlay = false

                        this.MessageBar("E", response.data.errMsg);
                    }
                })
                .catch((error) => {
                    this.$globalData.overlay = false
                    this.MessageBar("E", error);
                });
        }
    },
    mounted() {
        this.ClientMaster()
        if (!this.FetchChats) {
            this.GetAllChats()
            this.FetchChats = true
        }
    }
}
</script>
