import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ClientId:"",
    ProfilePic:"",
    MessageList:[],
    // ClientId:"PM011",
    // ProfilePic:"https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180"
  },
  getters: {
    handleEmptyString: () => (value) => {
      if (value == undefined){
        return 'Not Mentioned'
      }else{

        return value || 'Not Mentioned'; // Replace 'N/A' with any default value you want
      }
    },
  },
  mutations: {
    Setclient(state,client){
      state.ClientId=client
    },
    setProfilePic(state,pic){
      state.ProfilePic = pic
    },
    SetMessages(state,Msg){
      state.MessageList = Msg
    },
   
   
  },
  actions: {
  },
  modules: {
  }
})
