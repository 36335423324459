<template>
    <div>
        <BasicDetails></BasicDetails>
<Location></Location>
<carrers></carrers>
    </div>
</template>
<script>
import Location from './Location.vue'
import BasicDetails from './soulinfo.vue'
import carrers from './carrers.vue'
export default{
    components:{
        Location,BasicDetails,carrers
    }
}

</script>