<template>
  <div>
    <v-app-bar app class="pink elevation-0 " hide-on-scroll dense>
      <div class="d-flex align-center justify-center container">
        <!-- <v-toolbar-title class="text-button white--text">
          <v-img class="mt-2" src="https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Pmlogo.jpg" height="30" width="30" style="display: inline-block;"></v-img>
        <span class="">  profilematcher</span>
        </v-toolbar-title> -->
        <v-toolbar-title class="d-flex align-center">
          <v-img src="https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Pmlogo.jpg" height="30"
            width="30" class="mr-2"></v-img>
          <span class="white--text text-button">profile matcher</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex justify-end align-center">
          <v-tabs v-model="activeTab" background-color="pink" v-if="$vuetify.breakpoint.width > 700"
            slider-color="primary" color="black" slider-size="1">
            <v-tab class="white--text" v-for="item in items " :key="item.text" @click="navigate(item)" text>
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <!-- <v-app-bar-nav-icon v-if="$vuetify.breakpoint.width < 950"
            @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
          <span v-if="$vuetify.breakpoint.width > 950">
            <v-avatar v-if="$store.state.ProfilePic != ''" class="d-flex justify-center" size="30"
              style="cursor: pointer;margin-left: 50px;">
              <v-img :src="$store.state.ProfilePic"></v-img></v-avatar>
          </span>
        </v-toolbar-items>
      </div>
    </v-app-bar>
    <!-- <v-navigation-drawer app v-model="drawer" temporary right>
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary" rounded>
          <v-avatar v-if="$store.state.ProfilePic != ''" class="d-flex justify-center mt-2" size="150"
            style="cursor: pointer;margin-left: 50px;">
            <v-img :src="$store.state.ProfilePic"></v-img></v-avatar>
          <v-avatar v-else class="d-flex justify-center" color="blue lighten-4" size="150"
            style="cursor: pointer;margin-left: 50px;">
          </v-avatar>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-avatar>
              <v-icon class="pink--text">{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content @click="navigate(item)">
              <v-list-item-title class="font-weigth-bold">{{ item.text }}
                <v-badge v-if="item.text == 'Messages' && $globalData.MsgCount > 0" color="primary"
                  :content="$globalData.MsgCount">
                </v-badge>
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.subtittle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->


  </div>
</template>

<script>
import EventServices from '@/services/EventServices';

export default {
  data() {
    return {
      activeTab: 0,
      email: 'profileMatcher2024@gmail.com',
      subject: 'Queries regarding Profile Matching',
      body: 'Dear Team,\n\nI have the following queries:\n\n',
      curRouter: "",
      selectedItem: -1,
      drawer: false,
      items: [
        { text: 'Matches', router: '/profileMatcher', icon: 'mdi-account-multiple', subtittle: 'Find your soul' },
        { text: 'Profile', router: '/userProfile', icon: 'mdi-account', subtittle: 'Edit your profile' },
        { text: 'Messages', router: '/Inbox', icon: 'mdi-message-text-outline', subtittle: 'Check your messages' },
        { text: 'Logout', router: '/', icon: 'mdi-logout', subtittle: '' }
      ],
    }
  },
  methods: {
    ClearLocalStorage() {
      localStorage.setItem("clientId", ""),
        localStorage.setItem("ProfilePic", "")
      localStorage.setItem("MesssageList", "")
      localStorage.setItem("offlineMsg", "N")
    },
    navigate(item) {
      if (item.text == "Logout") {
        this.Logout()
      }
      this.curRouter = this.$route.path
      if (item.router != this.curRouter) {
        this.curRouter = item.router
        this.$router.push(item.router);
      }
    },
    Logout() {
      let logReq = { clientId: localStorage.getItem("clientId") };
      EventServices.Logout(logReq)
        .then((response) => {
          if (response.data.status == "S") {
            this.ClearLocalStorage()
            this.$router.push("/");
          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })


    }
  },
  computed: {
    emailLink() {
      const queryParams = new URLSearchParams({
        subject: this.subject,
        body: this.body
      });
      return `mailto:${this.email}?${queryParams.toString()}`;
    }
  }
}
</script>
