<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="pink--text">Info</span>
        <v-spacer></v-spacer> <v-icon class="red--text" @click="$emit('closeInfo')">mdi-close</v-icon></v-card-title>
      <v-form @submit.prevent="submitForm" class="pa-2">
        <v-row>
          <!-- <v-col cols="12" md="3">
            <v-autocomplete v-model="userInfo.profileCreatedBy" :items="LookupArr(dropDownList.profileCreatedList)"
              item-text="text" item-value="value" label="Profile Created By"></v-autocomplete>
          </v-col> -->
          <!-- <v-col cols="12" md="3">
            <v-text-field v-model="userInfo.name" label="Name"></v-text-field>
          </v-col> -->
          <v-col cols="12" md="3">
            <v-menu v-model="menu" :close-on-content-click="false" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="userInfo.dob" label="DOB" v-bind="attrs" v-on="on"
                  background-color="white"></v-text-field>
              </template>
              <v-date-picker v-model="userInfo.dob" @input="menu = false" :max="minDate" color="primary"
                no-title></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete attach :items="LookupArr(dropDownList.martialStatusList)" item-text="text"
              item-value="value" v-model="martialStatus" label="Marital Status"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="height" :items="LookupArr(dropDownList.heightList)" item-text="text"
              item-value="value" label="Height"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="grewup" :items="LookupArr(dropDownList.countryList)" item-text="text"
              item-value="value" label="Grew Up"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="diet" :items="LookupArr(dropDownList.dietList)" item-text="text" item-value="value"
              label="Diet"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="sunshine" :items="LookupArr(dropDownList.sunshineList)" item-text="text"
              item-value="value" label="Sunshine"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="bloodGroup" :items="LookupArr(dropDownList.bloodGroupList)" item-text="text"
              item-value="value" label="Blood Group"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="disablity" :items="LookupArr(dropDownList.disablityList)" item-text="text"
              item-value="value" label="Disability"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="userInfo.about" max-length="255" label="About"></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn type="submit" class="primary elevation-0"> Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      martialStatus: "",
      height: "",
      grewup: "",
      diet: "",
      sunshine: "",
      bloodGroup: "",
      disablity: "",
      menu: false,
      Indicator: "userinfo",
      GenderList: [{ value: "M", text: "Male" }, { value: "F", text: "Female" }],
    };
  },
  props: {
    userInfo: {}, dropDownList: {}, minDate: String, maxDate: String
  },
  methods: {
    assignValue() {

      this.height = this.getKeyByValue(this.dropDownList.heightList, this.userInfo.height)
      this.martialStatus = this.getKeyByValue(this.dropDownList.martialStatusList, this.userInfo.martialStatus)
      this.grewup = this.getKeyByValue(this.dropDownList.countryList, this.userInfo.grewup)
      this.diet = this.getKeyByValue(this.dropDownList.dietList, this.userInfo.diet)
      this.sunshine = this.getKeyByValue(this.dropDownList.sunshineList, this.userInfo.sunshine)
      this.bloodGroup = this.getKeyByValue(this.dropDownList.bloodGroupList, this.userInfo.bloodGroup)
      this.disablity = this.getKeyByValue(this.dropDownList.disablityList, this.userInfo.disablity)


    },
    getKeyByValue(object, value) {
      if (object != undefined) {
        return Object.keys(object).find(key => object[key] === value);
      } else {
        return ""
      }
    },
    submitForm() {

      this.userInfo.martialStatus = this.martialStatus
      this.userInfo.height = this.height
      this.userInfo.grewup = this.grewup
      this.userInfo.diet = this.diet
      this.userInfo.sunshine = this.sunshine
      this.userInfo.bloodGroup = this.bloodGroup
      this.userInfo.disablity = this.disablity

      this.$emit("userinfo", this.Indicator)
      // Handle form submission logic here, e.g., send data to an API or update the state
    },
    LookupArr(Data) {
      if (Data != null && Data != undefined) {

        return Object.keys(Data).map(key => ({
          value: key,
          text: Data[key]
        }));
      } else {
        return []
      }

    }

  },
  mounted() {
    if (!this.userInfo.dob) {
      this.userInfo.dob = this.minDate;
    }
    this.assignValue()
  }

};
</script>
