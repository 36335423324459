<template>
  <div>
    <v-layout>
      <v-flex lg12 sm12 xs12 md12 class="d-flex justify-center">
        <v-card class="elevation-0" width="750" outlined>
          <v-list three-line>
            <template v-for="(item, index) in uniqueConversations">
              <v-list-item :key="item.conversationId" @click="openChat(item)">
                <v-list-item-avatar>
                  <v-img :src="item.profilePic"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.conversation"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>{{ item.timestamp }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < uniqueConversations.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex>
        <ChatBox :sender="sender" :Reciver="Reciver" :senderName="senderName" :ReciverName="ReciverName"
          :chatDialog="chatDialog" @closeChat="chatDialog = false" :SenderPic="SenderPic" :ReciverPic="ReciverPic" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ChatInput from './ChatInput.vue';
import ChatBox from './chatBox.vue';

export default {
  components: {
    ChatInput,
    ChatBox,
  },
  data: () => ({
    senderName: "",
    SenderPic: "",
    ReciverPic: "",
    Reciver: '',
    sender: '',
    ReciverName: '',
    chatDialog: false,
  }),
  methods: {
    openChat(item) {
      this.sender = localStorage.getItem("clientId");
      this.Reciver = item.conversationId;
      this.ReciverName = this.sender === item.senderId ? item.reciverName : item.senderName;
      this.senderName = localStorage.getItem("username");

      this.SenderPic = this.sender === item.senderId ? item.senderProfilepic : item.reciverProfilepic;
      this.ReciverPic = this.sender === item.senderId ? item.reciverProfilepic : item.senderProfilepic;
      
      if ( this.SenderPic == ""){
        this.SenderPic ="https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/ChatAccount.jpg"
      }
      if ( this.ReciverPic == ""){
        this.ReciverPic ="https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/ChatAccount.jpg"
      }
     

      this.chatDialog = true;
      // this.sender = localStorage.getItem("clientId");
      // this.Reciver = item.conversationId;
      // this.ReciverName = item.reciverName;
      // // this.senderName = item.senderName
      // this.senderName = localStorage.getItem("username")

      // this.SenderPic = item.senderProfilepic
      // this.ReciverPic = item.reciverProfilepic
      // this.chatDialog = true;

    },
  },
  computed: {

    uniqueConversations() {
      const clientId = this.$store.state.ClientId;
      const uniqueConversations = [];
      const uniqueConvoIds = new Set();

      // Iterate over the messages list in reverse to get the latest messages
      for (let index = this.$store.state.MessageList.length - 1; index >= 0; index--) {
        const element = this.$store.state.MessageList[index];
        const conversationId = element.senderId === clientId ? element.receiverId : element.senderId;

        if (!uniqueConvoIds.has(conversationId)) {
          uniqueConvoIds.add(conversationId);
          uniqueConversations.push({
            conversationId,
            name: element.senderId === clientId ? element.reciverName : element.senderName,
            profilePic: element.senderId === clientId ? element.reciverProfilepic : element.senderProfilepic,
            conversation: element.conversation,
            timestamp: element.timestamp,
            reciverName: element.reciverName,
            senderName: element.senderName,
            senderProfilepic: element.senderProfilepic,
            reciverProfilepic: element.reciverProfilepic,
            senderId: element.senderId

          });
        }
      }
      return uniqueConversations;
    },
  },
  // mounted() {
  //   let messages = JSON.parse(localStorage.getItem("MesssageList"))
  //   console.log("messages",messages );
    
  //   if (messages != undefined && messages.length > 0)
  //     this.$store.commit('SetMessages', messages)
  // }
};
</script>
