<template>
    <v-card width="700" class="elevation-0 mx-auto">
        <!-- <div class="d-flex align-center justify-space-around"> -->
        <v-row >
            <v-col cols="7">
                <v-autocomplete :items="ClientList" class="rounded" v-model="filterName" dense outlined
                    append-icon="mdi-magnify"></v-autocomplete>
            </v-col>
            <v-col cols="4" class="d-flex justify-space-around">
                <v-btn small  @click="$emit('filterName', filterName)" class="primary ma-1">search</v-btn>
                <!-- <v-btn small @click="$emit('RESET')" icon class="mt-2 green--text"> <v-icon>mdi-backup-restore</v-icon></v-btn> -->
            </v-col>
           
        </v-row>

    </v-card>
</template>
<script>
export default {
    data() {
        return {
            filterName: ""
        }
    },
    props: {
        ClientList: []
    }
}
</script>