<template>
  <v-app >
    <Main></Main>
  </v-app>
</template>


<script>
// Import Firebase SDK
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, push } from "firebase/database"; // Import for Realtime Database
import Main from './views/Main.vue';

export default {
  name: 'App',
  components: {
    Main
  },

  data: () => ({
     logoutTimer: null,

  }),

  methods: {
    startLogoutTimer() {
      // Set the timer for 6 hours (21600000 milliseconds)
      this.logoutTimer = setTimeout(() => {
        this.logoutUser();
      }, 21600000);
    },
    logoutUser() {
      // Clear any authentication tokens, session data, etc.
      // this.$store.dispatch('logout'); // Assuming you're using Vuex for state management

      // Redirect to login page or home page
      this.$router.push("/");
      this.ClearLocalStorage()
    },
    resetLogoutTimer() {
      // Clear the existing timer and restart it
      clearTimeout(this.logoutTimer);
      this.startLogoutTimer();
    },
    ClearLocalStorage() {
      localStorage.setItem("clientId", ""),
        localStorage.setItem("ProfilePic", "")
        localStorage.setItem("offlineMsg","N")
    },
    // Method to save FCM token in Firebase Realtime Database
    saveToken(token) {
      // Reference to the tokens collection in the database
      const tokensRef = ref(this.database, 'fcmtoken');

      // Save the token with a unique key (e.g., user ID or a new push key)
      push(tokensRef, token).then(() => {
        console.log('FCM token stored successfully.');
      }).catch((error) => {
        console.error('Error storing FCM token:', error);
      });
    },

    // Initialize Firebase and handle FCM token
    initializeFirebase() {
      const firebaseConfig = {
        apiKey: "AIzaSyBoFzomNRThzgEvEKFGpkrJMc3C07MFanI",
        authDomain: "profilemacher.firebaseapp.com",
        projectId: "profilemacher",
        storageBucket: "profilemacher.appspot.com",
        messagingSenderId: "529095905896",
        appId: "1:529095905896:web:ad94a1cccbfc3a1c4161dd",
        measurementId: "G-HVP0Q0BMFN",
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);

      // Initialize Realtime Database
      this.database = getDatabase(app);

      // Initialize Firebase Cloud Messaging
      const messaging = getMessaging(app);

      // Request and get FCM token
      getToken(messaging, { vapidKey: 'BKe3bFlnYdZ3Z4qadodePrn6cy6FZYjH0FC2LhAcdC7gvyr1Ry3qnzejI7xGqhrEG8pU7nx8yBedkJnngLTonLM' }).then((currentToken) => {
        if (currentToken) {
          console.log("Token is: " + currentToken);
          // Save the token in Firebase Realtime Database
          this.saveToken(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });

      // Handle incoming messages
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // Handle foreground notifications
      });
    }
  },

  mounted() {
    // Initialize Firebase when the component is mounted
    this.initializeFirebase();
  },
  created() {
    this.startLogoutTimer();
  },
}
</script>
<!-- <script>
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import Main from './views/Main.vue';
export default {
  name: 'App',
  components: {
    Main
  },

  data: () => ({
    //
  }),
  methods:{
    saveToken(token) {
      // Reference to the tokens collection in the database
      const tokensRef = firebase.database().ref('fcmTokens');

      // Save the token with a unique key (e.g., user ID or a new push key)
      tokensRef.push(token).then(() => {
        console.log('FCM token stored successfully.');
      }).catch((error) => {
        console.error('Error storing FCM token:', error);
      });
    }
  }
  
}

const firebaseConfig = {
  apiKey: "AIzaSyBoFzomNRThzgEvEKFGpkrJMc3C07MFanI",
  authDomain: "profilemacher.firebaseapp.com",
  projectId: "profilemacher",
  storageBucket: "profilemacher.appspot.com",
  messagingSenderId: "529095905896",
  appId: "1:529095905896:web:ad94a1cccbfc3a1c4161dd",
  measurementId: "G-HVP0Q0BMFN"
};

const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

getToken(messaging, { vapidKey: 'BKe3bFlnYdZ3Z4qadodePrn6cy6FZYjH0FC2LhAcdC7gvyr1Ry3qnzejI7xGqhrEG8pU7nx8yBedkJnngLTonLM' }).then((currentToken) => {
  if (currentToken) {
    console.log("Token is :"+currentToken)
    // Send the token to your server and update the UI if necessary
    // ...
    this.S
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});



</script> -->
