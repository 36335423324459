<template>
    <div>
      <v-card class="elevation-0 ">
        <v-card-title >
          <span class="pink--text font-weight-bold ">Location Details</span>

          <v-spacer></v-spacer>
          <span style="cursor: pointer;text-decoration: underline;"class="primary--text caption"
            @click="$emit('ShowEdit')">
            Edit <v-icon small>mdi-chevron-right</v-icon> </span>
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-row  no-gutters>
          <v-col lg="4" sm="4" md="4" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="4" sm="4" md="4" cols="4">
                    State Of Residence
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="7" sm="7" md="7" cols="8">
                    {{ $store.getters.handleEmptyString(userProfile.stateofResidence) }} 
                  </v-col>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="4" sm="4" md="4" cols="4">
                    City Of Residence
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="7" sm="7" md="7" cols="8">
                    {{ $store.getters.handleEmptyString(userProfile.cityofResidence) }} 
                  </v-col>
  
                </v-row>
  
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col class="d-flex align-center" lg="1" > -->
            <v-divider vertical class="highlighted-divider mr-2"></v-divider>
          <!-- </v-col> -->
          <v-col lg="4" sm="4" md="4" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                    <v-col class="text-subtitle-2 text--disabled text-start " lg="4" sm="4" md="4" cols="4">
                      Residency Status
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="8" sm="8" md="8" cols="8">
                    {{ $store.getters.handleEmptyString(userProfile.residencyStatus) }} 
                  </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="4" sm="4" md="4" cols="4">
                    Zip / Pin code
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="8" sm="8" md="8" cols="8">
                    {{ $store.getters.handleEmptyString(userProfile.pincode) }} 
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
         
        </v-row>
      </v-card>
      <v-divider class="mt-1"></v-divider>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Your data here
      };
    },
    props:{
      userProfile:{}
    }
  };
  </script>
  
  <style>
  /* .info-text {
    font-size: 16px;
    color: #333;
    font-weight: 400;
  } */
  
   .highlighted-divider {
    border: 2px solid #ff4081;
    /* Highlight color */
  } 
  
  /* .v-card-title {
    background-color: #f8bbd0;
    padding: 16px;
    border-radius: 4px;
  } */
  
  /* .pa-4 {
    padding: 16px !important;
  } */
  
  /* .elevation-2 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  } */
  </style>