<template>
  <div>
    <v-card>

      <v-card-title><span class="pink--text">Education & Carrers</span>
        <v-spacer></v-spacer> <v-icon class="red--text" @click="$emit('closeInfo')">mdi-close</v-icon></v-card-title>
      <v-form @submit.prevent="submitForm" class="pa-2">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete v-model="qualification" :items="LookupArr(dropDownList.qualificationList)"
              item-text="text" item-value="value" label="Qualification" required></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-autocomplete v-model="annualIncome" :items="LookupArr(dropDownList.annualIncomeList)"
              item-text="text" item-value="value" label="Annual Income" required></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-autocomplete v-model="workingWith" :items="LookupArr(dropDownList.role)" item-text="text"
              item-value="value" label="Working With" required></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-autocomplete v-model="workingAs" :items="LookupArr(dropDownList.workingAs)" item-text="text"
              item-value="value" label="Working As" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model="career.employerName" label="Employer Name" required></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn type="submit" class="primary elevation-0"> Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qualification: "",
      annualIncome: "",
      workingWith: "",
      workingAs: "",
      Indicator: "carrer",
    };
  },
  props: {
    career: {}, dropDownList: {}
  },
  methods: {
    assignValue() {

      this.qualification = this.getKeyByValue(this.dropDownList.qualificationList, this.career.qualification)
      this.annualIncome = this.getKeyByValue(this.dropDownList.annualIncomeList, this.career.annualIncome)
      this.workingWith = this.getKeyByValue(this.dropDownList.workingWith, this.career.workingWith)
      this.workingAs = this.getKeyByValue(this.dropDownList.workingAs, this.career.workingAs)


    },
    getKeyByValue(object, value) {
      if (object != undefined) {
        return Object.keys(object).find(key => object[key] === value);
      } else {
        return ""
      }
    },
    submitForm() {
      this.career.qualification = this.qualification
      this.career.annualIncome = this.annualIncome
      this.career.workingWith = this.workingWith
      this.career.workingAs = this.workingAs

      this.$emit("carrer", this.Indicator)
      // Handle form submission logic here, e.g., send data to an API or update the state
    },
    LookupArr(Data) {
      if (Data != null && Data != undefined) {

        return Object.keys(Data).map(key => ({
          value: key,
          text: Data[key]
        }));
      } else {
        return []
      }

    }
  },
  mounted() {
    this.assignValue()
  }
};
</script>
