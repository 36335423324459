<template>
    <v-container>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
          <v-flex lg12 sm12 xs12 xl12>
            <v-text-field v-model="userDetails.EmailId" outlined dense label="Email Id" :rules="emailRules"
            required></v-text-field>
          </v-flex>
        <v-flex lg12 sm12 xs12 xl12>
            <v-text-field v-model="userDetails.userName" outlined dense label="Client Name" required></v-text-field>
        </v-flex>
        <v-autocomplete v-model="userDetails.gender" :items="GenderList" outlined dense item-value="code"
            item-text="Description" label="Gender"></v-autocomplete>
        <v-flex lg12 sm12 xs12 xl12>

        </v-flex>
        <v-flex lg12 sm12 xs12 xl12>
            <v-text-field v-model="userDetails.password" outlined dense label="Password" :rules="passwordRules"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword" required></v-text-field>
        </v-flex>
        <v-flex lg12 sm12 xs12 xl12>
            <v-text-field @keypress="onlyForNumber" v-model="userDetails.MobileNo" outlined dense label="MobileNo"
                :rules="mobileNoRules" required></v-text-field>
        </v-flex>
        <v-flex lg12 sm12 xs12 xl12>
          <v-autocomplete v-model="userDetails.profileCreated" :items="profileCreatedList" outlined dense 
           label="Profile Created"  item-text="value" item-value="text"></v-autocomplete>
        </v-flex>
        <v-flex lg12 sm12 xs12 xl12 class="d-flex align-center ">

            <v-icon @click="$emit('Back'),$refs.form.resetValidation()" dense color="primary" style="cursor: pointer;"
                class="float-left">mdi-arrow-left</v-icon>
            <v-btn class="ml-10" :disabled="ActivateValidation" @click="$emit('signup')" dense color="primary">
                Sign up
            </v-btn>

        </v-flex>
</v-form>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
          valid: false,
          NewDate:this.maxDate,
            showPassword:false,
            menu:false,
            GenderList: [{ code: "M", Description: "Male" }, { code: "F", Description: "Female" }],
            profileCreatedList:[
              {text:"1",value:"Self"},
              {text:"2",value:"Parent/Gaurdian"},
              {text:"3",value:"Siblings"},
              {text:"4",value:"Friends"},
              {text:"5",value:"Others"},
            ],
           
      mobileNoRules: [
        value => !!value || 'Mobile number is required',
        value => /^\d{10}$/.test(value) || 'Mobile number must be 10 digits'
      ],
      emailRules: [
        value => !!value || 'Email is required',
        value => /.+@.+\..+/.test(value) || 'Email must be valid'
      ],
      passwordRules: [
        value => !!value || 'Password is required',
        value => value.length >= 6 || 'Password must be at least 6 characters'
      ],
 
        }
    },
    props:{
        userDetails:{},ActivateValidation:Boolean,maxDate:String,currentDate:String,
        // profileCreatedList:[],
    },
    methods:{
      // calculateAge(value) {
      //       if (value) {
      //           const today = new Date();
      //           const dobDate = new Date(value);
      //           const diff = today.getTime() - dobDate.getTime();
      //           const ageDate = new Date(diff);

      //           const years = Math.abs(ageDate.getUTCFullYear() - 1970);
      //           const months = ageDate.getUTCMonth();

      //           let ageString = '';

      //           if (years > 0) {
      //               ageString += years + (years === 1 ? ' yr ' : ' yrs ');
      //           }

      //           if (months > 0) {
      //               ageString += months + (months === 1 ? ' mon' : ' mons');
      //           }
      //           this.userDetails.DOB = value
      //           return ageString.trim();
      //       } else {
      //           return ""
      //       }
      //   },
        onlyForNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // Allowing backspace and delete keys
      if (keyCode === 8 || keyCode === 46) {
        return;
      }

      // Allowing digits 0-9
      if (keyCode >= 48 && keyCode <= 57) {
        // Allowing input only if the total number of characters is less than or equal to 10
        if ($event.target.value.length >= 10) {
          $event.preventDefault();
        }
      } else {
        $event.preventDefault();
      }
    },
    }
}
</script>