<template>
  <div  >
      <ClientRecordsDesk @RESET="$emit('RESET')" :userList="userList" @SearchName="SearchName" :ClientList="ClientList"></ClientRecordsDesk>
    </div>  
</template>
<script>
import ClientRecordsDesk from './clientRecordComponents/ClientRecordsDesk.vue';
export default{
    components:{
    ClientRecordsDesk,
},
props:{
  userList:[],ClientList:[]
},
methods:{
    SearchName(value){
      // console.log("clientRecords");
      this.$emit('SearchName',value)
    },
}

}
</script>
