<template>
  <div>
    <v-card class="elevation-0 ">
      <v-card-title>
        <span class="pink--text font-weight-bold ">Info</span>
        <v-spacer></v-spacer>
        <span style="cursor: pointer;text-decoration: underline;"class="primary--text caption"  @click="$emit('ShowEdit')">
     Edit <v-icon small>mdi-chevron-right</v-icon> </span>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-row  no-gutters>
        <v-col lg="5" sm="5" md="5" cols="12">
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Name
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                {{ $store.getters.handleEmptyString(userProfile.name) }}  
                </v-col>
                <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Age
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8"> 
                  {{ calculateAge(userProfile.dob) }}
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  DOB
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.dob) }}  
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  martial Status
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.martialStatus) }}  
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Height
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.height) }}  
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Grew up
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.grewup) }}  
                </v-col>

              </v-row>

            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="d-flex align-center" lg="1" > -->
          <v-divider vertical class="highlighted-divider mr-2"></v-divider>
        <!-- </v-col> -->
        <v-col lg="5" sm="5" md="5" cols="12">
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Diet
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.diet) }} 
                </v-col>
                <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  sunshine
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.sunshine) }} 
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Blood Group
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.bloodgroup) }} 
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  Disablity
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                 {{ $store.getters.handleEmptyString(userProfile.disablity) }} 
                </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                  About
                </v-col>
                <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                  {{ $store.getters.handleEmptyString(userProfile.about).substring(0,30) }} 
                </v-col>

              </v-row>

            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="" lg="1" sm="1" md="1" cols="12">
          <v-divider vertical class="highlighted-divider mr-2"></v-divider>
        </v-col> -->
       
      </v-row>
    </v-card>
    <v-divider class="mt-1"></v-divider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Your data here
    };
  },
  props:{
    userProfile:{}
  },
  methods:{
    calculateAge(value) {
            if (value) {
                const today = new Date();
                const dobDate = new Date(value);
                const diff = today.getTime() - dobDate.getTime();
                const ageDate = new Date(diff);

                const years = Math.abs(ageDate.getUTCFullYear() - 1970);
                const months = ageDate.getUTCMonth();

                let ageString = '';

                if (years > 0) {
                    ageString += years + (years === 1 ? ' yr ' : ' yrs ');
                }

                if (months > 0) {
                    ageString += months + (months === 1 ? ' mon' : ' mons');
                }
                return ageString.trim();
            } else {
                return "Not Mentioned"
            }
        },
  }
};
</script>

<style>
/* .info-text {
  font-size: 16px;
  color: #333;
  font-weight: 500;
} */

 .highlighted-divider {
  border: 2px solid #ff4081;
  /* Highlight color */
} 

/* .v-card-title {
  background-color: #f8bbd0;
  padding: 16px;
  border-radius: 4px;
} */

/* .pa-4 {
  padding: 16px !important;
} */

/* .elevation-2 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
} */
</style>