<template>
    <div>
<v-dialog  v-model="chatDialog"  :fullscreen="$vuetify.breakpoint.width <= 600? true:undefined"
:transition="this.$vuetify.breakpoint.width <= 600 ?  'dialog-bottom-transition' : undefined"  max-width="400px">
  <ChatInput :sender="sender" :ReciverName="ReciverName" :Reciver="Reciver" @closeChat="$emit('closeChat')" :SenderPic="SenderPic" :ReciverPic="ReciverPic" :senderName="senderName"></ChatInput>
</v-dialog>

    </div>
</template>

<script>
import ChatInput from './ChatInput.vue';
export default {
    components: { ChatInput },
    props:{
      chatDialog:Boolean,sender:String,Reciver:String,ReciverName:String,senderName:String,SenderPic:String,ReciverPic:String
    }
};
</script>


