<template>
    <div>
        
        <v-tabs v-model="activeTab" color="pink" left text>
            <v-icon @click="$emit('closeprofile')" class="mx-3">mdi-arrow-left</v-icon>
            <v-tab>Detailed Profile</v-tab>
            <!-- <v-tab>partner preference</v-tab> -->
        </v-tabs>
        <v-window v-model="activeTab" class="elevation-0 mt-9">
            <v-window-item v-for="(tab, index) in tabs" :key="index">
                <Details v-if="activeTab == 0" :profileDatas="profileDatas"></Details>
                <partnerprefernce v-else></partnerprefernce>
            </v-window-item>
        </v-window>

    </div>
</template>
<script>
import partnerprefernce from "./partnerpreference.vue"
import Details from "./Detailedprofile.vue"
export default {
    components: {
        Details, partnerprefernce
    },
    props: {
        profileDatas: {}
    },
    data() {
        return {
            isDetailed: false,
            activeTab: 0,
            tabs: ["Detailedprofile", "partnerprofile"]

        }
    }
}

</script>