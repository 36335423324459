<template>
    <div>
        <v-img v-if="this.$vuetify.breakpoint.width > 900" src="../assets/loginDeskview.png" @error="handleImageError">

            <Auth></Auth>
        </v-img>
        <v-img v-else class="MobileView" @error="handleImageError">
            <div class="login-content">
    <h1>Join the Community</h1>
    <p>Find your soulmate and connect with people around the world!</p>
  </div>
            <Auth></Auth>
        </v-img>
    </div>
</template>
<script>
import Auth from '@/components/Auth/Auth.vue';
export default {
    components: {
        Auth
    },
    data() {
        return {
            mobileImg: "../assets/iPhone SE - 1.png",
            DesktopImg: "../assets/loginDeskview.png"
        }
    },

    methods: {
        handleImageError(error) {
            console.error('Error loading image:', error);
        },
        HandleAuth() {
            if (localStorage.getItem("clientId") != "" && localStorage.getItem("clientId") != null) {
                console.log("Client Id not empty");
                this.$store.commit('Setclient', localStorage.getItem("clientId"))
                this.$store.commit('setProfilePic', localStorage.getItem("ProfilePic"))
                this.$router.push('/profileMatcher')
            }
        }

    },
    mounted() {
        this.HandleAuth()
    }


}
</script>
<style scoped>
.MobileView {
    background: linear-gradient(135deg, #ff5fd7, #feb47b); /* gradient with shades of pink and orange */
    height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-content {
    text-align: center;
    color: white;
    margin-top: 10px; /* Adjust as needed */
}

h1 {
    font-size: 36px;
    font-weight: bold;
}

p {
    font-size: 18px;
    margin-top: 10px;
}
</style>