import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

export default new Vuetify({

  theme: {
    dark: false, // Set this to true for dark theme, and false for light theme
    themes: {
      light: {
        pink:"#FF4081",
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        footer: '#f8f9fc',
        header: '#F8F9FC',
        btnColor: '#0965da',
        content: '#365048',
        contentHead: '#2A394E',
        smechip: '#1565C0',
        bluedark:'#385F73'
      },
      dark: {
        pink:"#FF4081",
        primary: '#1976D2',
        secondary: '#757575',
        accent: '#64B5F6',
        footer: '#272727',
        btnColor: '#0965da',
        bluedark:'#385F73'

      },
    },
  },
});
