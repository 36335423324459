import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=670d0ca0&scoped=true"
var script = {}
import style0 from "./loader.vue?vue&type=style&index=0&id=670d0ca0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670d0ca0",
  null
  
)

export default component.exports