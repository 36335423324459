import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/views/Auth.vue'
import Fetchmaster from '@/views/ClientMaster.vue'
import userProfile from '@/views/userProfile.vue'
import MessageBox from '@/views/MessageBox.vue'
import lookup from '@/views/lookup.vue'
// import Randompost from '@/views/Randompost.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: "/profileMatcher",
    name: "fetchmaster",
    component: Fetchmaster,
    meta: {
      needsAuth: true,
    }

  },
  {
    path: "/Inbox",
    name: "Inbox",
    component: MessageBox,
    meta: {
      needsAuth: true,
    }

  },
  {
    path: "/userProfile",
    name: "userProfile",
    component: userProfile,
    meta: {
      needsAuth: true,
    }
  },
    {
      path: "/lookup",
      name: "lookup",
      component: lookup,
      meta: {
        needsAuth: true,
      }
    }

  // {
  //   path: "/posts",
  //   name: "Randompost",
  //   component: Randompost,
  //   meta: {
  //     needsAuth: true,
  //   }

  // },
  // use on Future
  // {
  //   path: "/posts",
  //   name: "post",
  //   component: post,
  //   meta: {
  //     needsAuth: true,
  //   }
// =========================
  // }

  // {
  //   path: "/report",
  //   name: "report",
  //   component: Report,
  //   meta: {
  //     needsAuth: true,
  //     // title: 'Report',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'This Page is allowed for admin only.'
  //       }
  //     ]
  //   },
  // },

  // {
  //   path: "/assigntask",
  //   name: "roletask",
  //   component: TaskMaster,
  //   meta: {
  //     needsAuth: true,
  //   },
  // },
  // {
  //   path: "/createtask",
  //   name: "roleMaster",
  //   component: RoleMaster,
  //   meta: {
  //     needsAuth: true,
  //   },
  // },



  // {
  //   path: "/managerole",
  //   name: "memberUser",
  //   component: MemberUser,
  //   meta: {
  //     needsAuth: true,
  //   },
  // },
  // {
  //   path: "/fetchmaster",
  //   name: "fetchmaster",
  //   component: Fetchmaster,
  //   meta: {
  //     needsAuth: true,
  //   }
  // },
  // {
  //   path: "/domainsetup",
  //   name: "domainsetup",
  //   component: DomainMaster,
  //   meta: {
  //     needsAuth: true,
  //   }
  // },
  // {
  //   path: "/lookup",
  //   name: "lookup",
  //   component: LookUp,
  //   meta: {
  //     needsAuth: true,
  //   }
  // },
  // {
  //   path: "*",
  //   name: "pagenotfound",
  //   component: PageNotFound,
  //   meta: { hideAppBar: true }, // Add this meta field
  // },
  // {
  //   path: "/underConstruction",
  //   name: "underConstruction",
  //   component: UnderConstruction,
  //   meta: { hideAppBar: true }, // Add this meta field
  // },



  // {
  //   path: "/logdownload",
  //   name: "logdownload",
  //   component: logdownload,
  // },
  // {
  //   path: "/versioncontrol",
  //   name: "versioncontrol",
  //   component: versionControl,
  //   meta: {
  //     needsAuth: true,
  //   }
  // },
]
const router = new VueRouter({
  routes,
  mode: "history",
  // base: 'http://localhost:29095',
  scrollBehavior(to, from, savedPosition) {
    // If there is a savedPosition (e.g., user pressed back/forward buttons), use it
    if (savedPosition) {
      return savedPosition;
    } else {
      // Otherwise, scroll to the top of the page
      return { x: 0, y: 0 };
    }
  },
})

export default router
