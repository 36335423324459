<template>
    <div>
      <v-card class="elevation-0 ">
        <v-card-title>
          <span class="pink--text font-weight-bold ">partner Info</span>
          <v-spacer></v-spacer>
          <v-icon    class="primary--text">mdi-pencil-outline</v-icon>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-row  no-gutters>
          <v-col lg="5" sm="5" md="5" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Age 
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    19-25
                  </v-col>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Height
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    4' 11"(149cm) to 5' 7"(170cm)
                  </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Religion / Community
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    Hindu
                  </v-col>
                </v-row>
  
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col class="d-flex align-center" lg="1" > -->
            <v-divider vertical class="highlighted-divider mr-2"></v-divider>
          <!-- </v-col> -->
          <v-col lg="5" sm="5" md="5" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Diet
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    veg
                  </v-col>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Mother tongue
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    Telugu, English
                  </v-col><v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Marital status
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    Never Married
                  </v-col>
  
                </v-row>
  
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col class="" lg="1" sm="1" md="1" cols="12">
            <v-divider vertical class="highlighted-divider mr-2"></v-divider>
          </v-col> -->
         
        </v-row>
      </v-card>
      <v-divider class="mt-1"></v-divider>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Your data here
      };
    }
  };
  </script>
  
  <style>
  /* .info-text {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  } */
  
   .highlighted-divider {
    border: 2px solid #ff4081;
    /* Highlight color */
  } 
  
  /* .v-card-title {
    background-color: #f8bbd0;
    padding: 16px;
    border-radius: 4px;
  } */
  
  /* .pa-4 {
    padding: 16px !important;
  } */
  
  /* .elevation-2 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  } */
  </style>