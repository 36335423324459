<template>
    <div>
        <v-card height="auto" class="elevation-0 ">
            <v-row>
                <v-col lg="3" sm="3" md="3" cols="12">
                    <v-img v-if="profileDatas.userInfo.profilepic !=''" aspect-ratio="1" :src="profileDatas.userInfo.profilepic"></v-img>
                    <v-img v-else aspect-ratio="1" src="https://via.placeholder.com/300"></v-img>
                </v-col>
                <v-col lg="9" sm="9" md="9" cols="12">
                    <v-card-title class=" text-uppercase d-flex ma-2 font-weight-bold">
                        {{ profileDatas.userInfo.name }}
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-divider class="ma-2"></v-divider>
                    <div
                  class="elevation-0 "
                  style="border: 1px solid #e0e0e0; border-radius: 10px; padding: 16px"
                >
                <div class="text-h5 pink--text font-weight-light">About</div>
                <span>{{ handleEmpty(profileDatas.userInfo.about) }}</span>
            
            </div>
            <div
                  class="elevation-0 mt-2"
                  style="border: 1px solid #e0e0e0; border-radius: 10px; padding: 16px"
                >
            <div class="text-h5 pink--text font-weight-light">Info</div>
            <v-row class="mt-1">
                <v-col lg="6" sm="6" md="6" cols="12">
                    <span><v-icon class="primary--text">mdi-calendar-outline</v-icon> {{handleEmpty( calculateAge( profileDatas.userInfo.dob)) }}, <v-icon class="red--text">mdi-human-male-height</v-icon> {{ handleEmpty(profileDatas.userInfo.height) }}</span>
                </v-col>
                <v-col  lg="6" sm="6" md="6" cols="12">
                 <span><v-icon class="pink--text">mdi-account-multiple</v-icon>   {{handleEmpty(profileDatas.userInfo.martialStatus)}}</span>
                </v-col>
                <v-col lg="6" sm="6" md="6" cols="12">
                    <span>{{handleEmpty(profileDatas.religiousBackGround.motherTongue)  }}</span>
                </v-col>
                <v-col  lg="6" sm="6" md="6" cols="12">
                 <span>
<v-icon class="green--text">mdi-home-outline</v-icon>
                   {{ handleEmpty(profileDatas.locations.cityofResidence) }}, {{handleEmpty(profileDatas.locations.stateofResidence) }}
                </span>
                </v-col>
                <v-col lg="6" sm="6" md="6" cols="12">
                    <span> <v-icon class="accent--text">mdi-account-group-outline</v-icon> {{handleEmpty(profileDatas.religiousBackGround.caste)}},{{ handleEmpty(profileDatas.religiousBackGround.community)  }}</span>
                </v-col>
                <v-col  lg="6" sm="6" md="6" cols="12">
                    <span>
                        <v-icon class="pink--text">mdi-briefcase-outline</v-icon>
                       {{ handleEmpty(profileDatas.carrers.workingAs)  }}</span>
                </v-col>
            </v-row>
           
            </div>
            <div
                  class="elevation-0 mt-2"
                  style="border: 1px solid #e0e0e0; border-radius: 10px; padding: 16px"
                >
                <div class="text-h5 pink--text font-weight-light ">Education & carrers</div>
                <v-row class="mt-1">
                    <v-col cols="12"><span><v-icon class="yellow--text">mdi-school-outline</v-icon> {{ handleEmpty( profileDatas.carrers.qualification) }}</span> </v-col>
                    <v-col cols="12"><span><v-icon class="primary--text">mdi-office-building-outline</v-icon> {{handleEmpty(profileDatas.carrers.workingWith)  }}</span></v-col>
                    <v-col cols="12"><span><v-icon class="pink--text"> mdi-briefcase-outline</v-icon> {{ handleEmpty(profileDatas.carrers.workingAs)  }}</span></v-col>
                    <v-col cols="12"><span><v-icon class="green--text">mdi-cash-multiple</v-icon> {{ handleEmpty(profileDatas.carrers.annualIncome)  }}</span></v-col>
                </v-row>
            </div>
                </v-col>
            </v-row>

        </v-card>

    </div>
</template>
<script>
export default {
    props: {
        profileDatas: {}
    },
    data() {
        return {
            items: [
                {
                    color: 'red lighten-2',
                    icon: 'mdi-star',
                },
                {
                    color: 'purple darken-1',
                    icon: 'mdi-book-variant',
                },
                {
                    color: 'green lighten-1',
                    icon: 'mdi-airballoon',
                },
                {
                    color: 'indigo',
                    icon: 'mdi-buffer',
                },
            ],
        }
    },
    methods:{
        handleEmpty(value) {
      if (value == "") {
        return "Not Mentioned"
      } else {
        return value
      }
    },
    calculateAge(value) {
            if (value) {
                const today = new Date();
                const dobDate = new Date(value);
                const diff = today.getTime() - dobDate.getTime();
                const ageDate = new Date(diff);

                const years = Math.abs(ageDate.getUTCFullYear() - 1970);
                const months = ageDate.getUTCMonth();

                let ageString = '';

                if (years > 0) {
                    ageString += years + (years === 1 ? ' yr ' : ' yrs ');
                }

                if (months > 0) {
                    ageString += months + (months === 1 ? ' mon' : ' mons');
                }
                return ageString.trim();
            } else {
                return ""
            }
        },

    }
}
</script>