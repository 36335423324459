<template>
    <div>
        <Inbox  v-if="$store.state.MessageList.length > 0" ></Inbox>
        <div v-else>
            <v-card class="mx-auto mt-5 d-flex justify-center align-center elevation-0
            rounded-xxl" width="300" height="100">
                <p class="text-center">Not Yet Started To chat.</p>

            </v-card>
        </div>
    </div>
</template>
<script>
import Inbox from '@/components/chatComponents/Inbox.vue';
export default {
    components: {
        Inbox
}
}
</script>