<template>
    <div>
<NavBar></NavBar>
    </div>
</template>
<script>
import NavBar from './NavigationComponents/NavBar.vue';
export default{
    components:{
        NavBar
    }
}

</script>