<template>
    <div>
       

            <MainAuth ></MainAuth>
    </div>
</template>
<script>
import MainAuth from './AuthComponents/MainAuth.vue';
export default {
    components: {
        MainAuth,
    }
}
</script>