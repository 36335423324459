<template>
  <div>
    <Search @filterName="SearchName" :ClientList="ClientList" @RESET="$emit('RESET')"></Search>
    <v-card height="auto" class="elevation-3 mt-8" v-for="(client, index) in Allclients" :key="index">
      <v-row>
        <v-col lg="3" sm="3" md="3" cols="12">
          <v-img v-if="client.userInfo.profilepic != ''" aspect-ratio="1" :src="client.userInfo.profilepic"></v-img>
          <v-img v-else aspect-ratio="1"
            :src="profilePicture(client)"></v-img>

        </v-col>
        <v-col lg="9" sm="9" md="9" cols="12">
          <v-card-title class=" text-uppercase d-flex ma-2">
            {{ client.userInfo.name }}
            <v-spacer></v-spacer>
            <v-btn outlined class="text-button teal--text mr-2 " small @click="$emit('showChat', client)">Chat</v-btn>
            <v-btn outlined class="text-button pink--text " small @click="$emit('clientDetials', client)">View</v-btn>
          </v-card-title>
          <v-divider class="ma-2"></v-divider>
          <v-row class="ma-2">
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-calendar-outline</v-icon> {{
      handleEmpty(calculateAge(client.userInfo.dob)) }}, <v-icon
                  class="pink--text">mdi-human-male-height</v-icon> {{ handleEmpty(client.userInfo.height) }}</span>
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span> <v-icon class="pink--text">mdi-account-multiple</v-icon>
                {{ handleEmpty(client.userInfo.martialStatus) }}
              </span>
              <!-- Never Married -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-account-group</v-icon> {{
      handleEmpty(client.religiousBackGround.religion) }},{{ handleEmpty(client.religiousBackGround.caste)
                }}</span>
              <!-- Hindu,Naidu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span>
                <v-icon class="pink--text">mdi-home-outline</v-icon>
                {{ handleEmpty(client.locations.cityofResidence) }},{{ handleEmpty(client.locations.stateofResidence) }}
              </span>
              <!-- chennai,TamilNadu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              {{ handleEmpty(client.religiousBackGround.motherTongue) }}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-briefcase-outline</v-icon></span> {{
      handleEmpty(client.carrers.workingAs) }},{{ handleEmpty(client.carrers.annualIncome) }}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="12" sm="12" md="12" cols="12">
              <span> {{ handleEmpty(client.userInfo.about).substring(0, 15) }}</span>
              <span v-if="client.userInfo.about != ''">...</span>
              <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, eius enim sapiente assumenda facere esse eligendi ab, impedit qui, dolore provident totam quis molestias officia et sit harum voluptates veniam. -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import Search from '@/components/ClientRecords/clientRecordComponents/SearchField.vue'
export default {
  components: {
    Search
  },
  methods: {
    SearchName(value) {
      // console.log("clientList");
      this.$emit('SearchName', value)
    },
    handleEmpty(value) {
      if (value == "") {
        return "Not Mentioned"
      } else {
        return value
      }
    },
    calculateAge(value) {
      if (value) {
        const today = new Date();
        const dobDate = new Date(value);
        const diff = today.getTime() - dobDate.getTime();
        const ageDate = new Date(diff);

        const years = Math.abs(ageDate.getUTCFullYear() - 1970);
        const months = ageDate.getUTCMonth();

        let ageString = '';

        if (years > 0) {
          ageString += years + (years === 1 ? ' yr ' : ' yrs ');
        }

        if (months > 0) {
          ageString += months + (months === 1 ? ' mon' : ' mons');
        }
        return ageString.trim();
      } else {
        return ""
      }
    },
    profilePicture(client) {
      if (client.userInfo.profilepic !== '') {
        return client.userInfo.profilepic;
      }
      if (client.userInfo.gender === 'M') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
      }
      if (client.userInfo.gender === 'F') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
      }
      return 'https://via.placeholder.com/300';
    }
  },
  props: {
    Allclients: Array, ClientList: []
  },
  computed: {

    // filterItems(){
    //   console.log(this.posts.filter(item => item.userName = this.filterName));
    //   return this.filterName == "" ? this.posts : this.posts.filter(item => item.userName = this.filterName )

    //   // return this.filterName == "" ? Allclients : Allclients.filter(item => item.userName = this.filterName )
    // }
  },
  name: 'master',
  data() {
    return {
      clientData: {},
      filterName: "",
      posts: [
        {
          userName: 'Priya Sharma',
          about: ' "Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation."',
          clientId: 'PM001',
          pofilePic: 'https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Nisha Patel',
          about: '"Lover of art, travel, and good food."',
          clientId: 'PM002',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.cQ-6ZGATxLv277VDDrIv0gHaJQ&pid=Api&P=0&h=180',
        }, {
          userName: 'Deepika Singh',
          about: 'Fitness enthusiast and outdoor adventurer.',
          clientId: 'PM003',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.DtfTFM2Na57RCG3iWt_vHgHaJQ&pid=Api&P=0&h=180',
        }, {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM004',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        }, {
          clientId: 'PM005',
          pofilePic: '',
        }, {
          clientId: 'PM006',
          pofilePic: '',
        }, {
          clientId: 'PM007',
          pofilePic: '',
        }, {
          clientId: '8',
          pofilePic: '',
        }, {
          clientId: 'PM009',
          pofilePic: '',
        }, {
          clientId: 'PM0010',
          pofilePic: '',
        }, {
          clientId: 'PM0011',
          pofilePic: '',
        }, {
          clientId: 'PM0012',
          pofilePic: '',
        },
        // Add more data objects as needed
      ]
    }
  },


}
</script>
