<template>
  <div>
    <!-- Chat messages -->
    <v-card class="align-space-between" elevation="0" :height="$vuetify.breakpoint.width < 600 ? $vuetify.breakpoint.height : 450">
      <v-toolbar v-if="ReciverName != ''" dense class="elevation-0 pink" dark>
        <v-toolbar-title class="text-button">{{ ReciverName }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('closeChat')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-subtitle class="d-flex justify-center">
  Your chat will not be stored for privacy reasons.
</v-card-subtitle>
      <div class="message-container">
        <v-list dense class="message-list"
        :height="$vuetify.breakpoint.width > 600 ? 200 : $vuetify.breakpoint.height - 250">
          <v-list-item v-for="(message, index) in filteredMessages" :key="index">
            
            <v-list-item-content :class="$store.state.ClientId == message.senderId ? 'sender' : 'receiver'">
              <v-list-item-title><v-chip color="pink" outlined> {{ message.conversation }}</v-chip></v-list-item-title>
              <!-- <v-list-item-subtitle class="text-caption d-flex align-center">
                <v-icon color="grey" small class="mr-1">mdi-clock-outline</v-icon>
                {{ message.timestamp }}
                </v-list-item-subtitle> -->
              <!-- <v-list-item-subtitle class="text-caption"></v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <!-- Message input -->
       <v-card-actions>

      <v-row class=" ma-1  ">
        <v-col cols="8">
          <v-text-field v-model="Newmessage" label="Type your message..." dense outlined
            @keyup.enter="sendMessage"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn class="primary-text" outlined @click="sendMessage"
            :disabled="Newmessage == '' || ReciverName == ''">SEND</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import EventServices from '@/services/EventServices';
export default {
  props: {
    sender: String,
    senderName: String,
    SenderPic: String,
    ReciverPic: String,
    Reciver: String,
    ReciverName: String
  },
  data() {
    return {
      Newmessage: '',
      ws: null,
      Msg: {
        senderId: "",
        receiverId: "",
        conversation: "",
        senderName: "",
        reciverName: "",
        senderProfilepic: "",
        reciverProfilepic: "",
        updatedDate: "",
        timestamp: "",
      },
    }
  },
  computed: {
    filteredMessages() {
      return this.$store.state.MessageList.filter(message => message.senderId === this.Reciver || message.receiverId === this.Reciver);
    },
    // Computed property for the formatted UpdatedDate
    formattedUpdatedDate() {
      let now = new Date();

      // Extract individual date and time components in local time
      let year = now.getFullYear();
      let month = String(now.getMonth() + 1).padStart(2, '0'); // Month (MM)
      let day = String(now.getDate()).padStart(2, '0'); // Day (DD)
      let hours = String(now.getHours()).padStart(2, '0'); // Hours (HH)
      let minutes = String(now.getMinutes()).padStart(2, '0'); // Minutes (mm)

      // Combine them into the desired format: YYYY-MM-DD HH:mm
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    // Computed property for the formatted Timestamp
    formattedTimestamp() {
      let now = new Date();

      // Extract individual date and time components
      let day = String(now.getDate()).padStart(2, '0'); // Day (DD)
      let month = String(now.getMonth() + 1).padStart(2, '0'); // Month (MM)
      let year = now.getFullYear(); // Year (YYYY)

      let hours = now.getHours(); // Hours (24-hour format)
      let minutes = String(now.getMinutes()).padStart(2, '0'); // Minutes (mm)

      // Determine AM or PM
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12 || 12; // Convert to 12-hour format
      hours = String(hours).padStart(1, '0'); // Add leading zero for single digit

      // Combine date and time into the desired format
      return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
    }
  },
  methods: {
    sendMessage() {
      this.$globalData.overlay = true;
      this.Msg.conversation = this.Newmessage;
      this.Msg.senderId = this.sender;
      this.Msg.receiverId = this.Reciver;
      this.Msg.senderName = this.senderName;
      this.Msg.reciverName = this.ReciverName;
      this.Msg.senderProfilepic = this.SenderPic;
      this.Msg.reciverProfilepic = this.ReciverPic;
      this.Msg.updatedDate = this.formattedUpdatedDate
      this.Msg.timestamp = this.formattedTimestamp
      console.log(" this.Msg", this.Msg);
      
      EventServices.SetUserChats(this.Msg)
        .then(response => {
          if (response.data.status === "S") {
            this.$globalData.overlay = false;
            this.Newmessage = "";
            console.log(" ...response.data.chatItems", ...response.data.chatItems);
            this.$store.state.MessageList.push(...response.data.chatItems);
            localStorage.setItem("MesssageList", JSON.stringify(this.$store.state.MessageList) )
          } else {
            this.$globalData.overlay = false;
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch(error => {
          this.$globalData.overlay = false;
          this.MessageBar("E", error);
        });
    },
    makeCall(phoneNumber) {
      if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      } else {
        alert('Phone number is missing');
      }
    }
  }
}
</script>

<style scoped>
.sender {
  text-align: right;
}

.receiver {
  text-align: left;
}

/* .message-card {
  border: 1px solid #efe8e8;
} */
.message-list {
  height: 300;
  padding: 0;
  overflow-y: auto;
}

.message-container {
  /* max-height: 400; */
  padding: 8px;
}
</style>
