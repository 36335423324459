<template>
  <div>
    <v-card>
      <v-card-title><span class="pink--text">Religious Background</span>
        <v-spacer></v-spacer> <v-icon class="red--text" @click="$emit('closeInfo')">mdi-close</v-icon></v-card-title>
      <v-form @submit.prevent="submitForm" class="pa-2">
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="religion" :items="LookupArr(dropDownList.religionList)"
              item-text="text" item-value="value" label="Religion"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="community" :items="LookupArr(dropDownList.communityLst)"
              item-text="text" item-value="value" label="Community"></v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" md="6">
            <v-autocomplete v-model="subcommunity" :items="LookupArr(dropDownList.subcommunityList)"
              item-text="text" item-value="value" label="Subcommunity"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="gothram" :items="LookupArr(dropDownList.gothramList)"
              item-text="text" item-value="value" label="Gothram"></v-autocomplete>
          </v-col> -->
          <v-col cols="12" md="6">
            <v-autocomplete v-model="motherTongue" :items="LookupArr(dropDownList.languages)"
              item-text="text" item-value="value" label="Mother Tongue"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="caste" :items="LookupArr(dropDownList.casteList)"
              item-text="text" item-value="value" label="Caste"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn type="submit" class="primary elevation-0"> Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      religion: "",
      community: "",
      subcommunity: "",
      gothram: "",
      motherTongue: "",
      caste: "",
      Indicator:"religious",
    };
  },
  props: {
    religiousBackGround: {}, dropDownList: {}
  },
  methods: {
    assignValue() {
      this.religion = this.getKeyByValue(this.dropDownList.religionList, this.religiousBackGround.religion)
      this.community = this.getKeyByValue(this.dropDownList.communityLst, this.religiousBackGround.community)
      this.subcommunity = this.getKeyByValue(this.dropDownList.subcommunityList, this.religiousBackGround.subcommunity)
      this.gothram = this.getKeyByValue(this.dropDownList.gothramList, this.religiousBackGround.gothram)
      this.motherTongue = this.getKeyByValue(this.dropDownList.languages, this.religiousBackGround.motherTongue)
      this.caste = this.getKeyByValue(this.dropDownList.casteList, this.religiousBackGround.caste)




    },
    getKeyByValue(object, value) {
      if (object != undefined) {
        return Object.keys(object).find(key => object[key] === value);
      } else {
        return ""
      }
    },
    submitForm() {
      this.religiousBackGround.religion = this.religion
      this.religiousBackGround.community = this.community
      this.religiousBackGround.subcommunity = this.subcommunity
      this.religiousBackGround.gothram = this.gothram
      this.religiousBackGround.motherTongue = this.motherTongue
      this.religiousBackGround.caste = this.caste


      this.$emit("religious", this.Indicator)
      // Handle form submission logic here, e.g., send data to an API or update the state
    },
    LookupArr(Data) {
      if (Data != null && Data != undefined) {

        return Object.keys(Data).map(key => ({
          value: key,
          text: Data[key]
        }));
      } else {
        return []
      }
    }
  },
  mounted() {
    this.assignValue()
  }
};
</script>
