<template>
  <div>
    <v-container>

      <ClientList v-if="showData" :Allclients="userList" @clientDetials="ShowDetails" :ClientList="ClientList"
        @RESET="$emit('RESET')" @SearchName="SearchName" @showChat="showChat"></ClientList>
      <ClientDetails v-else :profileDatas="profileDatas" @closeprofile="showData = true"></ClientDetails>
      <chatBox :sender="senderId" :Reciver="ReciverId" :ReciverName="ReciverName" :chatDialog="chatDialog"
        @closeChat="chatDialog = false" :senderName="senderName" :SenderPic="SenderPic" :ReciverPic="ReciverPic">
      </chatBox>
    </v-container>


  </div>
</template>
<script>
import ClientList from './ClientList.vue'
// import ClientDetailDialog from './clientDetailDialog.vue';
// import ClientTableDesk from './clientTableDesk.vue';
import chatBox from '@/components/chatComponents/chatBox.vue';
// import clientTableMobile from './clientTableMobile.vue'

import ClientDetails from "./ClientDetails.vue"
export default {
  data() {
    return {
      ReciverPic: "",
      SenderPic: "",
      senderName: "",
      showData: true,
      senderId: "",
      ReciverId: "",
      ReciverName: "",
      userDialog: false,
      showRecords: false,
      chatDialog: false,
      chatItems: {},
      profileDatas: {}
    }
  },
  props: {
    userList: [], ClientList: []
  },
  components: {
    ClientList,
    chatBox,
    ClientDetails
  },
  methods: {
    SearchName(value) {
      // console.log("clientRecordsDesk");

      this.$emit('SearchName', value)
    },
    showChat(item) {
      // console.log(item,"item");
      this.senderId = localStorage.getItem("clientId")
      this.ReciverId = item.userInfo.clientId
      this.ReciverName = item.userInfo.name
      this.SenderPic = localStorage.getItem("ProfilePic")
      this.senderName = localStorage.getItem("username")
      this.ReciverPic = this.profilePicture(item)


      
      this.chatDialog = true
    },

    ShowDetails(item) {
      this.profileDatas = { ...item }
      this.showData = false
    },
    profilePicture(client) {
      if (client.userInfo.profilepic !== '') {
        return client.userInfo.profilepic;
      }
      if (client.userInfo.gender === 'M') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
      }
      if (client.userInfo.gender === 'F') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
      }
      return 'https://via.placeholder.com/300';
    }

  },
  mounted() {
    this.showRecords = true
  },

}
</script>